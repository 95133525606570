import "./setupTalGel";

import ReactDOM from "react-dom/client";
import "./index.css";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Error } from "./Components/Error";
import { Otp } from "./Pages/Otp";
import { Maintenance } from "./Pages/Maintenance";
import { Success } from "./Pages/Success";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        errorElement: <Error />,
        children: [
          {
            path: process.env.REACT_APP_ERROR_PATH!,
            element: <Error />,
          },
          {
            path: "/maintenance",
            element: <Maintenance />,
          },
          {
            path: ":corroItemId",
            element: <Otp />,
          },
          {
            path: "/success",
            element: <Success />,
          },
        ],
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(<RouterProvider router={router} />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
