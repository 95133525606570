import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Api as CorroDeliveryApi } from "./corroDeliveryApi";
import { ErrorCodes } from "./customErrorMapping";

export const corroApi = new CorroDeliveryApi({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "api-key": process.env.REACT_APP_API_KEY,
    apikey: process.env.REACT_APP_API_KEY,
  },
});

export const AxiosInterceptor = ({ children }) => {
  const [isSet, setIsSet] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const interceptor = corroApi.instance.interceptors.response.use(
      (response) => response,
      (error) => {
        // If an error has a custom message, we will handle it in the component
        if (Object.values(ErrorCodes).includes(error.response?.data?.type)) {
          return Promise.reject(error);
        } else {
          navigate(process.env.REACT_APP_ERROR_PATH!, {
            state: { error: error.response?.data },
          });
        }
      }
    );
    setIsSet(true);

    return () => corroApi.instance.interceptors.response.eject(interceptor);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return isSet && children;
};
