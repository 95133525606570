import {
  useGelFormData,
  GelContainerLite,
  GelParagraph,
  GelBoxLayout,
  GelForm,
  GelFormField,
  GelTextInput,
  GelButton,
  GelHeading1,
  GelHeading6,
  GelIcon,
  GelLabel,
} from "@tal-gel/components";
import { getGelTokens } from "@tal-gel/theming";
import { useContext, useEffect, useState } from "react";
import { corroApi } from "../api/axiosInterceptor";
import { ErrorCodeMappings, ErrorCodes } from "../api/customErrorMapping";
import { CorroUserContext } from "../App";
import { base64toBlobAsync } from "../Helpers/FileBuilder";
import { useNavigate } from "react-router-dom";
import { useCountdown } from "../hooks/useCountdown";
import GetSupportPhoneUsingKey from '../Helpers/Helper';
const gelTokens = getGelTokens();

export const OtpForm = () => {
  const [formError, setFormError] = useState<string>("");
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const userContext = useContext(CorroUserContext);
  const [targetTimestamp, setTargetTimestamp] = useState<number>(0);

  useEffect(() => {
    if (userContext.otpSentState.otpSent) {
      // 5 minutes in milliseconds
      setTargetTimestamp(new Date().getTime() + 5 * 60 * 1000);
      userContext.otpSentState.setOtpSent(false);
    }
    // eslint-disable-next-line
  }, [userContext.otpSentState.otpSent]);

  const navigate = useNavigate();

  const { formData, onFormDataChange, onFormDataReset, resetForm } =
    useGelFormData({
      otpInput: "",
    });

  const onFormDataChangeHandler = (event) => {
    onFormDataChange(event);

    if (event.target.value.length === 6) {
      let regNumeric = /^[0-9]+$/i;
      if (!regNumeric.test(event.target.value)) {
        setFormError("Passcode must be 6 digits, numbers only");
        setIsFormValid(false);
      } else {
        setFormError("");
        setIsFormValid(true);
      }
    } else {
      setFormError("Passcode must be 6 digits, numbers only");
      setIsFormValid(false);
    }
  };

  const supportPhone = GetSupportPhoneUsingKey(userContext.subContentKeyState) ?? userContext.memberBrandState.memberBrand.supportPhone!;

  const onSubmit = () => {
    setIsFormValid(false);
    return corroApi.corro.authCorroPost(
      {
        id: userContext.corroIdState.corroId!,
        otp: formData.otpInput,
      },
      {
        Mobile: userContext.useDebugState.useDebug
          ? userContext.textMobileNumberState.textMobileNumber
          : undefined,
        Channel: userContext.useDebugState.useDebug
          ? userContext.channelState.channel
          : undefined,
      }
    );
  };

  const onResponse = async (response) => {
    setFormError("");
    if (response?.status === 200) {
      return base64toBlobAsync(response.data.content)
        .then((blob) => {
          const blobUrl = URL.createObjectURL(blob as Blob);
          try {
            const newWindow = window.open(blobUrl);
            if (newWindow) {
              navigate("/success", { replace: true });
            } else {
              window.location.href = blobUrl;
            }
          } catch {
            navigate(process.env.REACT_APP_ERROR_PATH!, {
              state: {
                error: { type: ErrorCodes.MISC_ERROR_CODE_POPUP_SETTINGS },
              },
            });
          }
        })
        .catch((error) => {
          navigate(process.env.REACT_APP_ERROR_PATH!);
        });
    } else {
      navigate(process.env.REACT_APP_ERROR_PATH!);
    }
  };

  const onError = (error) => {
    setIsFormValid(false);
    const errorObject = ErrorCodeMappings.get(error.response.data.type);
    setFormError(
      errorObject!.description!.replace(
        "[%%BrandPhoneNumber%%]",
        supportPhone!
      )
    );
  };

  const twoDigits = (num) => String(num).padStart(2, "0");
  const [minutes, seconds] = useCountdown(targetTimestamp);
  const isCountingDown = minutes + seconds > 0;
  const countdownText = isCountingDown
    ? `The passcode will expire in ${twoDigits(minutes)}:${twoDigits(
      seconds
    )} minutes.`
    : "";

  useEffect(() => {
    if (!isCountingDown) {
      const error = {
        response: { data: { type: ErrorCodes.AUTH_ERROR_CODE_EXPIRED } },
      };
      onError(error);
      formData.otpInput = "";
    } else {
      setFormError("");
    }
    // eslint-disable-next-line
  }, [isCountingDown]);

  return (
    <GelContainerLite>
      <GelHeading1 style={{ fontWeight: "bold" }}>Verification</GelHeading1>
      <GelParagraph marginTop={gelTokens.global.sizeBase}>
        We've sent a passcode by SMS to your registered mobile number (
        {userContext.mobileNumberState.mobileNumber}).
      </GelParagraph>
      <GelParagraph marginTop={gelTokens.global.sizeBaseX2}>
        {countdownText}
      </GelParagraph>

      <GelBoxLayout
        gap="xlarge"
        space="auto"
        distribution={"start"}
        style={{ marginTop: gelTokens.global.sizeBaseX4 }}
      >
        <GelForm
          labelAtTop
          disableOnSubmit
          onSubmit={onSubmit}
          reset={resetForm}
          onReset={onFormDataReset}
          parseResponseBody={false} // Remove this if you use `fetch` in `onSubmit`
          onResponse={onResponse}
          onError={onError}
          disabled={!isCountingDown}
        >
          <GelFormField
            tooltip=""
            aria-label="OTP Section"
            style={{
              marginBottom: gelTokens.global.sizeNone,
            }}
          >
            <GelHeading6 as="h2">
              Enter the 6-digit code and confirm
            </GelHeading6>
            <GelTextInput
              label="OTP Entry Field"
              width={"9em"}
              name="otpInput"
              value={formData.otpInput}
              onChange={(event) => onFormDataChangeHandler(event)}
              maxLength="6"
              minLength="6"
              style={{
                letterSpacing: ".5em",
                textAlign: "center",
                padding: 0,
                marginTop: gelTokens.global.sizeBaseX3,
              }}
            />

            <GelBoxLayout
              gap="0"
              distribution="start"
              space="1"
              style={{
                minHeight: gelTokens.global.sizeBaseX6,
                marginTop: gelTokens.global.sizeBase,
              }}
            >
              {formError && (
                <GelLabel
                  style={{
                    color: gelTokens.global.themeColorTextDanger,
                    fontWeight: gelTokens.global.fontWeightLight,
                    fontSize: gelTokens.global.fontSizeCaption,
                  }}
                >
                  <GelIcon
                    name="AlertCircle"
                    color={getGelTokens().global.themeColorIconDanger}
                    width={"1.5em"}
                    inline
                  />
                  {formError}
                </GelLabel>
              )}
            </GelBoxLayout>
          </GelFormField>
          <GelFormField
            style={{
              marginTop: gelTokens.global.sizeBaseX2,
            }}
          >
            <GelButton
              disabled={!isFormValid}
              aria-disabled={!isFormValid}
              aria-label="confirm button"
              label="confirm button"
              name="" // Makes it a managed component
              primary
              medium
              submit
            >
              Confirm
            </GelButton>
          </GelFormField>
        </GelForm>
      </GelBoxLayout>
    </GelContainerLite>
  );
};
