import { getGelTokens } from "@tal-gel/theming";

const gelTokens = getGelTokens();

export const Divider = () => {
  return (
    <>
      <hr
        style={{
          height: gelTokens.global.sizeBaseHalf,
          borderWidth: 0,
          backgroundColor: gelTokens.brand.brandColorGrayT20,
          marginTop: gelTokens.global.sizeBaseX8,
          marginBottom: gelTokens.global.sizeBaseX8,
        }}
      />
    </>
  );
};
