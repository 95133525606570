import { GelLink } from "@tal-gel/components";

export const PhoneLink = ({ phoneNumber, label }) => {
  var phoneNotNull = phoneNumber ?? "";
  var noWhiteSpacePhone = phoneNotNull.replace(/\s+/g, "");
  return (
    <GelLink style={{ whiteSpace: "nowrap", }} aria-label={label} href={`tel: ${noWhiteSpacePhone}`}>
      {phoneNotNull}
    </GelLink>
  );
};
