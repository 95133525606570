import { GelLink } from "@tal-gel/components";
import { getGelTokens } from "@tal-gel/theming";

function ContentOnDivider({ link, text }) {
  if (!text) {
    return null;
  }

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <div
        style={{
          flex: "1",
          content: "",
          padding: "1px",
          backgroundColor: getGelTokens().brand.brandColorGrayT20,
          marginRight: getGelTokens().global.sizeBaseX3,
        }}
      ></div>
      {link ? (
        <GelLink
          aria-label="Privacy Policy"
          href={link ?? "#"}
          rel="noreferrer"
          target="_blank"
        >
          {text}
        </GelLink>
      ) : (
        text
      )}
      <div
        style={{
          flex: "1",
          content: "",
          padding: "1px",
          backgroundColor: getGelTokens().brand.brandColorGrayT20,
          marginLeft: getGelTokens().global.sizeBaseX3,
        }}
      ></div>
    </div>
  );
}

export default ContentOnDivider;
