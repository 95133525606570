import {
  GelBoxLayout,
  GelCol,
  GelContainerLite,
  GelLink,
  GelFootNote,
  GelRow,
  GelScreenDetectorContext,
} from "@tal-gel/components";
import React, { useContext } from "react";

import { getGelTokens } from "@tal-gel/theming";
import { BrandConfigProps } from "../../props/brandConfigProps";

const gelTokens = getGelTokens();
const FooterGIO: React.FC<BrandConfigProps> = (brandData) => {
  const { screen, isXsScreen } = useContext(GelScreenDetectorContext);
  const imgStyle = isXsScreen(screen)
    ? {
        height: gelTokens.global.sizeBaseX8,
      }
    : {
        height: gelTokens.global.sizeBaseX8,
        marginLeft: gelTokens.global.sizeBaseX8,
        cursor: "pointer",
      };

  return (
    <GelContainerLite
      style={{
        padding: 0,
        backgroundColor: gelTokens.global.themeColorBackgroundDefault,
        color: gelTokens.brand.brandColorBlack,
      }}
    >
      <GelRow
        gutter="medium"
        style={{
          backgroundColor: getGelTokens().brand.brandColorPrimary1,
          height: getGelTokens().global.sizeBaseHalf,
          margin: 0,
        }}
      >
        <GelCol sm={12}></GelCol>
      </GelRow>
      <GelRow gutter="medium" style={{ padding: gelTokens.global.sizeBaseX4 }}>
        <GelCol sm={12} md={3}></GelCol>
        <GelCol sm={12} md={6}>
          <GelBoxLayout
            style={{ padding: gelTokens.global.sizeBase }}
            justify-content="space-between"
            distribution={"middle"}
          >
            <GelBoxLayout
              distribution="start"
              space={"auto"}
              style={{ padding: gelTokens.global.sizeBase }}
            >
              {brandData?.footer?.socialNetworks?.map((item, index) => (
                <GelLink
                  key={index}
                  aria-label={item?.name ?? ""}
                  href={item.link ?? "#"}
                  rel="noreferrer"
                  style={{
                    border: "none",
                  }}
                >
                  <img src={item.icon ?? ""} alt={item?.name ?? ""} />
                </GelLink>
              ))}
            </GelBoxLayout>
            <GelBoxLayout style={{ textAlign: "end" }}>
              <GelLink
                style={{
                  border: "none",
                }}
                href={brandData.logo.link}
                rel="noreferrer"
                target="_blank"
              >
                <img
                  src={brandData.logo.image}
                  alt={brandData.name}
                  style={imgStyle}
                />
              </GelLink>
            </GelBoxLayout>
          </GelBoxLayout>
        </GelCol>
      </GelRow>
      <GelRow style={{ padding: gelTokens.global.sizeBaseX4, paddingTop: "0" }}>
        <GelCol sm={12} md={3}></GelCol>
        <GelCol sm={12} md={6}>
          <hr />

          <GelBoxLayout
            space="auto"
            gap="large"
            distribution="start"
            marginY={getGelTokens().global.sizeBaseX6}
          >
            {brandData.footer.links.privacyPolicy ? (
              <GelLink
                aria-label="Privacy Policy"
                href={brandData.footer.links.privacyPolicy ?? "#"}
                rel="noreferrer"
                target="_blank"
              >
                Privacy Policy
              </GelLink>
            ) : null}
            {brandData.footer.links.security ? (
              <GelLink
                aria-label="Security"
                href={brandData.footer.links.security ?? "#"}
                rel="noreferrer"
              >
                Security
              </GelLink>
            ) : null}
          </GelBoxLayout>
          {brandData.footer.text ? (
            <GelFootNote>{brandData.footer.text}</GelFootNote>
          ) : null}
        </GelCol>
      </GelRow>
    </GelContainerLite>
  );
};

export default FooterGIO;
