enum ErrorCodes {
  AUTH_ERROR_CODE_INCORRECT = "AuthErrorCodes.Incorrect",
  AUTH_ERROR_CODE_EXPIRED = "AuthErrorCodes.Expired",
  AUTH_ERROR_TOO_MANY_ATTEMPTS = "AuthErrorCodes.TooManyAttempts",
  AUTH_ERROR_TOO_MANY_REQUESTS = "AuthErrorCodes.TooManyRequests",

  MISC_ERROR_CODE_INVALID_LINK = "MiscErrorCodes.InvalidLink",
  MISC_ERROR_CODE_POPUP_SETTINGS = "MiscErrorCodes.PopupSettings",
  MISC_ERROR_CODE_INVALID_MOBILE = "MiscErrorCodes.InvalidMobile",
  MISC_ERROR_FETCH_DOCUMENT = "DocumentFetchException",
}

let ErrorCodeMappings = new Map<
  string,
  { title: string; description: string }
>();

ErrorCodeMappings.set(ErrorCodes.AUTH_ERROR_CODE_INCORRECT, {
  title: "Passcode is incorrect",
  description:
    "The code you have entered is incorrect. Please try again or call [%%BrandPhoneNumber%%].",
});
ErrorCodeMappings.set(ErrorCodes.AUTH_ERROR_CODE_EXPIRED, {
  title: "Passcode is expired",
  description:
    "Passcode has expired. Please request a new passcode and try again.",
});
ErrorCodeMappings.set(ErrorCodes.MISC_ERROR_FETCH_DOCUMENT, {
  title: "Unable to obtain document",
  description: "Unfortunately, we are unable to retrieve the document.",
});
ErrorCodeMappings.set(ErrorCodes.AUTH_ERROR_TOO_MANY_ATTEMPTS, {
  title: "Too many incorrect passcode attempts",
  description:
    "Too many fail attempts made. For security reasons, access has been locked. Please try again in 5 minutes.",
});
ErrorCodeMappings.set(ErrorCodes.AUTH_ERROR_TOO_MANY_REQUESTS, {
  title: "Too many passcode requests",
  description:
    "Too many requests made. For security reasons, access has been locked. Please try again in 1 hour.",
});
ErrorCodeMappings.set(ErrorCodes.MISC_ERROR_CODE_POPUP_SETTINGS, {
  title: "PDF is blocked by the browser",
  description: "Unable to open the PDF. Please check your popup settings.",
});
ErrorCodeMappings.set(ErrorCodes.MISC_ERROR_CODE_INVALID_MOBILE, {
  title: "Invalid mobile number selected",
  description: "We are unable to validate your phone number.",
});
ErrorCodeMappings.set("DEFAULT", {
  title: "Unknown error",
  description: "Unfortunately, something has gone wrong.",
});

export { ErrorCodes, ErrorCodeMappings };
