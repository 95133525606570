import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Loader } from "../Components/Loader";
import { OtpForm } from "../Components/OtpForm";
import { RequestNew } from "../Components/RequestNew";
import { corroApi } from "../api/axiosInterceptor";
import { Divider } from "../Components/Atoms/Divider";
import { CorroUserContext } from "../App";
import { ErrorInvalidLink } from "../Components/ErrorInvalidLink";
import { ErrorCodeMappings } from "../api/customErrorMapping";

export const Otp = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const userContext = useContext(CorroUserContext);
  const navigate = useNavigate();

  useEffect(() => {
    const corroItemId = userContext.corroIdState.corroId;
    corroApi.validateandconfirm
      .validateAndConfirmPost({
        Id: corroItemId!,
        Mobile: userContext.useDebugState.useDebug
          ? userContext.textMobileNumberState.textMobileNumber
          : undefined,
        Channel: userContext.useDebugState.useDebug
          ? userContext.channelState.channel
          : undefined,
      })
      .then((response) => {
        userContext.otpSentState.setOtpSent(true);
        userContext.corroIdState.setCorroId(response.data?.id!);
        userContext.mobileNumberState.setMobileNumber(
          response.data.maskedMobileNumber!
        );
        setIsLoaded(true);
      })
      .catch((error) => {
        userContext.otpSentState.setOtpSent(false);
        setIsLoaded(true);
        if (ErrorCodeMappings.get(error.response?.data?.type)) {
          navigate(process.env.REACT_APP_ERROR_PATH!, {
            state: { error: error.response?.data },
          });
        } else { setIsInvalid(true); }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isLoaded) {
    return <Loader />;
  } else {
    return isInvalid ? (
      <ErrorInvalidLink />
    ) : (
      <>
        <OtpForm />
        <Divider />
        <RequestNew />
      </>
    );
  }
};
