import { GelParagraph, GelHeading6, GelHeading2 } from "@tal-gel/components";
import { getGelTokens } from "@tal-gel/theming";
import { PhoneLink } from "../Components/Atoms/PhoneLink";

const gelTokens = getGelTokens();

export const Maintenance = () => {
  return (
    <>
      <GelHeading2
        style={{
          color: gelTokens.global.themeColorTextAccent,
        }}
      >
        Temporarily down for maintenance
      </GelHeading2>
      <GelHeading6>
        We are performing scheduled maintenance and will be back online shortly
      </GelHeading6>
      <hr />
      <GelParagraph>
        If you need help, please contact support centre on{" "}
        <PhoneLink
          phoneNumber={process.env.REACT_APP_SUPPORT_PHONE!}
          label={
            "Call our support team on " + process.env.REACT_APP_SUPPORT_PHONE!
          }
        />
      </GelParagraph>
    </>
  );
};
