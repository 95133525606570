import {
  GelAccordion,
  GelButton,
  GelContainerLite,
  GelHeading1,
  GelHeading6,
  GelParagraph,
} from "@tal-gel/components";
import { getGelTokens } from "@tal-gel/theming";
import { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CorroUserContext } from "../App";
import { Divider } from "./Atoms/Divider";
import { PhoneLink } from "./Atoms/PhoneLink";
import { ErrorCodeMappings } from "../api/customErrorMapping";

const gelTokens = getGelTokens();
const errorDefault = "DEFAULT";
type LocationState = {
  error: any;
};

export const Error = () => {
  const userContext = useContext(CorroUserContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { error } = (location?.state ?? {}) as LocationState;
  document.title = "An error has occurred";

  const retryLink = () => {
    navigate(userContext.linkState.link);
  };

  const errorObject =
    ErrorCodeMappings.get(error?.type) ?? ErrorCodeMappings.get(errorDefault);
  return (
    <GelContainerLite>
      <GelHeading1>An error has occurred</GelHeading1>
      <GelHeading6>{errorObject?.description}</GelHeading6>

      {userContext.linkState.link &&
        userContext.linkState.link !== process.env.REACT_APP_ERROR_PATH! && (
          <>
            <GelParagraph marginTop={gelTokens.global.sizeBaseX4}>
              You may try again by clicking the retry button.
            </GelParagraph>
            <GelButton
              primary
              medium
              onClick={retryLink}
              style={{ marginTop: gelTokens.global.sizeBaseX4 }}
            >
              Retry
            </GelButton>
          </>
        )}

      <Divider />
      <GelParagraph style={{ marginTop: gelTokens.global.sizeBaseX4 }}>
        If the problem persists, contact us on{" "}
        <PhoneLink
          phoneNumber={userContext.memberBrandState.memberBrand.supportPhone!}
          label={
            "Call our support team on " +
            userContext.memberBrandState.memberBrand.supportPhone!
          }
        />
      </GelParagraph>
      {error?.referenceId ? (
        <GelAccordion
          title={"Error information"}
          minContentHeight={"fit-content"}
          style={{
            marginTop: gelTokens.global.sizeBaseX4,
            backgroundColor: gelTokens.brand.brandColorGrayT05,
          }}
        >
          {error.title ? (
            <div style={{ padding: gelTokens.global.sizeBaseHalf }}>
              Error type: {errorObject?.title}
            </div>
          ) : null}
          {error.referenceId ? (
            <div style={{ padding: gelTokens.global.sizeBaseHalf }}>
              Reference Id: {error.referenceId}
            </div>
          ) : null}
        </GelAccordion>
      ) : null}
    </GelContainerLite>
  );
};
