import {
  GelAccordion,
  GelContainerLite,
  GelHeading1,
  GelHeading6,
  GelParagraph,
} from "@tal-gel/components";
import { getGelTokens } from "@tal-gel/theming";
import { Divider } from "./Atoms/Divider";
import { PhoneLink } from "./Atoms/PhoneLink";
import { useLocation } from "react-router-dom";
import { ErrorCodeMappings } from "../api/customErrorMapping";

const gelTokens = getGelTokens();
type LocationState = {
  error: any;
};

export const ErrorInvalidLink = () => {
  const location = useLocation();
  const { error } = (location?.state ?? {}) as LocationState;
  const errorDefault = "DEFAULT";

  const errorObject =
    ErrorCodeMappings.get(error?.type) ?? ErrorCodeMappings.get(errorDefault);
  return (
    <GelContainerLite>
      <GelHeading1>This link is invalid</GelHeading1>
      <GelHeading6>
        We are unable to find your document with this link.
      </GelHeading6>
      <Divider />
      <GelParagraph style={{ marginTop: gelTokens.global.sizeBaseX4 }}>
        To access the document, please contact the support centre on{" "}
        <PhoneLink
          phoneNumber={process.env.REACT_APP_SUPPORT_PHONE!}
          label={
            "Call our support team on " + process.env.REACT_APP_SUPPORT_PHONE!
          }
        />
      </GelParagraph>
      {error?.referenceId ? (
        <GelAccordion
          title={"Error information"}
          minContentHeight={"fit-content"}
          style={{
            marginTop: gelTokens.global.sizeBaseX4,
            backgroundColor: gelTokens.brand.brandColorGrayT05,
          }}
        >
          {errorObject?.title ? (
            <div style={{ padding: gelTokens.global.sizeBaseHalf }}>
              Error type: {errorObject?.title}
            </div>
          ) : null}
          {error.referenceId ? (
            <div style={{ padding: gelTokens.global.sizeBaseHalf }}>
              Reference Id: {error.referenceId}
            </div>
          ) : null}
        </GelAccordion>
      ) : null}
    </GelContainerLite>
  );
};
