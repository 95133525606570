import React from "react";
import brandFooters from "./BrandFooters";

interface FooterContainerProps {
  brand: string;
}

const FooterContainer: React.FC<FooterContainerProps> = ({ brand }) => {
  const BrandFooter = brandFooters[brand];
  if (!BrandFooter) {
    console.log("No footer found for the brand", brand);
    return <div />;
  }

  return <BrandFooter />;
};

export default FooterContainer;
