import {
  GelBoxLayout,
  GelCol,
  GelContainerLite,
  GelFootNote,
  GelLink,
  GelParagraph,
  GelRow,
} from "@tal-gel/components";
import React from "react";

import { getGelTokens } from "@tal-gel/theming";
import { BrandConfigProps } from "../../props/brandConfigProps";

const borderLogo =
  getGelTokens().global.sizeBase +
  "px solid " +
  getGelTokens().brand.brandColorPrimary2;
const FooterSuncorp: React.FC<BrandConfigProps> = (brandData) => {
  return (
    <GelContainerLite
      style={{
        padding: 0,
        backgroundColor: getGelTokens().brand.brandColorWhite,
      }}
    >
      <GelRow
        gutter="medium"
        style={{
          backgroundColor: getGelTokens().brand.brandColorPrimary1,
          height: getGelTokens().global.sizeBaseX2,
          margin: 0,
        }}
      >
        <GelCol sm={12}></GelCol>
      </GelRow>
      <GelRow
        gutter="medium"
        style={{
          backgroundColor: getGelTokens().global.themeColorBackgroundDark,
          color: getGelTokens().global.themeColorTextInverse,
          padding: getGelTokens().global.sizeBaseX6,
        }}
      >
        <GelCol sm={12} md={3}></GelCol>
        <GelCol sm={12} md={6}>
          <GelRow
            gutter="medium"
            style={{
              paddingBottom: getGelTokens().global.sizeBaseX3,
              paddingLeft: 0,
              borderBottom: borderLogo,
              borderBottomStyle: "solid",
              borderBottomColor: getGelTokens().brand.brandColorPrimary2,
            }}
          >
            <GelCol sm={12}>
              <a
                href={brandData.logo.link}
                rel="noreferrer"
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <GelParagraph style={{ fontWeight: "bold" }} inverse={true}>
                  Suncorp
                </GelParagraph>
              </a>
            </GelCol>
          </GelRow>
          <GelRow
            gutter="medium"
            style={{
              paddingTop: getGelTokens().global.sizeBaseX6,
              paddingRight: 0,
            }}
          >
            <GelCol sm={12}>
              <GelBoxLayout distribution="end" space="auto">
                {brandData?.footer?.socialNetworks?.map((item, index) => (
                  <GelLink
                    key={index}
                    aria-label={item?.name ?? ""}
                    href={item.link ?? "#"}
                    rel="noreferrer"
                  >
                    <img
                      width={getGelTokens().global.sizeBaseX6}
                      src={item.icon ?? ""}
                      alt={item?.name ?? ""}
                    />
                  </GelLink>
                ))}
              </GelBoxLayout>
            </GelCol>
          </GelRow>
        </GelCol>
        <GelCol sm={12} md={3}></GelCol>
      </GelRow>
      <GelRow
        gutter="medium"
        style={{
          padding: getGelTokens().global.sizeBaseX6,
          paddingTop: 0,
        }}
      >
        <GelCol sm={12} md={3}></GelCol>
        <GelCol sm={12} md={6}>
          <GelBoxLayout
            space="auto"
            gap="large"
            distribution="start"
            marginY={getGelTokens().global.sizeBaseX6}
          >
            {brandData.footer.links.privacyPolicy ? (
              <GelLink
                aria-label="Privacy Policy"
                href={brandData.footer.links.privacyPolicy ?? "#"}
                rel="noreferrer"
              >
                Privacy Policy
              </GelLink>
            ) : null}
            {brandData.footer.links.security ? (
              <GelLink
                aria-label="Security"
                href={brandData.footer.links.security ?? "#"}
                rel="noreferrer"
              >
                Security
              </GelLink>
            ) : null}
          </GelBoxLayout>
          {brandData.footer.text ? (
            <GelFootNote>{brandData.footer.text}</GelFootNote>
          ) : null}
        </GelCol>
        <GelCol sm={12} md={3}></GelCol>
      </GelRow>
    </GelContainerLite>
  );
};

export default FooterSuncorp;
