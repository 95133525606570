import {
  GelAlert,
  GelButton,
  GelContainerLite,
  GelHeading6,
  GelParagraph,
} from "@tal-gel/components";
import { getGelTokens } from "@tal-gel/theming";

import { useContext, useState } from "react";

import { useCountdown } from "../hooks/useCountdown";
import { CorroUserContext } from "../App";
import { corroApi } from "../api/axiosInterceptor";
import { PhoneLink } from "./Atoms/PhoneLink";
import { ErrorCodeMappings } from "../api/customErrorMapping";
import { useNavigate } from "react-router-dom";
import GetSupportPhoneUsingKey from '../Helpers/Helper';

export const RequestNew = () => {
  const [targetTimestamp, setTargetTimestamp] = useState<number>(0);
  const [openInfoAlert, setOpenInfoAlert] = useState(false);
  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const gelTokens = getGelTokens();
  const userContext = useContext(CorroUserContext);
  const navigate = useNavigate();

  const resendVerification = () => {
    const DELAY_SECONDS_IN_MS = 6 * 1000;
    const timestamp = new Date().getTime() + DELAY_SECONDS_IN_MS;
    setOpenInfoAlert(false);
    setOpenErrorAlert(false);
    corroApi.validateandconfirm
      .validateAndConfirmPost({
        Id: userContext.corroIdState.corroId!,
        Mobile: userContext.useDebugState.useDebug
          ? userContext.textMobileNumberState.textMobileNumber
          : undefined,
        Channel: userContext.useDebugState.useDebug
          ? userContext.channelState.channel
          : undefined,
      })
      .then(() => {
        userContext.otpSentState.setOtpSent(true);
        setOpenInfoAlert(true);
      })
      .catch((error) => {
        userContext.otpSentState.setOtpSent(false);
        if (ErrorCodeMappings.get(error.response?.data?.type)) {
          navigate(process.env.REACT_APP_ERROR_PATH!, {
            state: { error: error.response?.data },
          });
        } else {
          setOpenErrorAlert(true);
        }
      });

    setTargetTimestamp(timestamp);
  };
  const twoDigits = (num) => String(num).padStart(2, "0");

  const [minutes, seconds] = useCountdown(targetTimestamp);
  const isCountingDown = minutes + seconds > 0;
  const btnCountdownText = isCountingDown
    ? `Request a new passcode in ${twoDigits(minutes)}:${twoDigits(seconds)}`
    : "Request a new passcode";



  const supportPhone = GetSupportPhoneUsingKey(userContext.subContentKeyState) ?? userContext.memberBrandState.memberBrand.supportPhone!;

  return (
    <GelContainerLite>
      <GelHeading6 as="h3">Didn't receive the passcode?</GelHeading6>
      <GelButton
        secondary
        medium
        onClick={resendVerification}
        style={{ marginTop: gelTokens.global.sizeBaseX4 }}
        disabled={isCountingDown}
      >
        {btnCountdownText}
      </GelButton>
      <GelParagraph style={{ marginTop: gelTokens.global.sizeBaseX4 }}>
        Not your phone number? To update, please call{" "}
        <PhoneLink
          phoneNumber={supportPhone!}
          label={
            "Call our support team on " + supportPhone!
          }
        />.
      </GelParagraph>
      <GelAlert
        success
        open={openInfoAlert && isCountingDown}
        onClose={() => setOpenInfoAlert(false)}
      >
        A new passcode has been sent to your mobile number
      </GelAlert>
      <GelAlert
        danger
        open={openErrorAlert && isCountingDown}
        onClose={() => setOpenErrorAlert(false)}
      >
        An error has occurred.
      </GelAlert>
    </GelContainerLite>
  );
};
