import {
  GelBoxLayout,
  GelCol,
  GelContainerLite,
  GelIcon,
  GelLink,
  GelParagraph,
  GelRow,
  GelScreenDetectorContext,
} from "@tal-gel/components";
import React, { useContext } from "react";

import { getGelTokens } from "@tal-gel/theming";
import { BrandConfigProps } from "../../props/brandConfigProps";

const gelTokens = getGelTokens();

const sizeBaseX25 = gelTokens.global.sizeBaseUnit * 12;

const navLinkStyle = {
  color: gelTokens.global.themeColorTextLinkInverse,
  width: "100px",
  paddingBottom: "10px",
  fontWeight: "bold",
  fontSize: "13px",
  borderBottom: "1px solid",
  borderColor: gelTokens.brand.brandColorGrayT40,
};

const textStyle = {
  fontSize: "11px",
  color: gelTokens.brand.brandColorGrayT40,
  fontWeight: "bold",
  textSizeAdjust: "100%",
  lineHeight: "15px",
  letterSpacing: "normal",
};

const FooterAsteron: React.FC<BrandConfigProps> = (brandData) => {
  const { screen, isXsScreen } = useContext(GelScreenDetectorContext);
  const imgStyle = isXsScreen(screen)
    ? {
      height: sizeBaseX25,
      marginRight: gelTokens.global.sizeBaseX6,
    }
    : {
      height: gelTokens.global.sizeBaseX18,
      marginLeft: gelTokens.global.sizeBaseX8,

      cursor: "pointer",
    };

  return (
    <GelContainerLite
      style={{
        padding: gelTokens.global.sizeBaseX4,
        backgroundColor: gelTokens.global.themeColorBackgroundDark,
        color: gelTokens.brand.brandColorGrayT40,
      }}
    >
      <GelRow gutter="medium">
        <GelCol sm={12} md={2}></GelCol>
        <GelCol sm={12} md={1}>
          <a href={brandData.logo.link} rel="noreferrer" target="_blank">
            <img
              src={brandData.logo.image}
              alt={brandData.name}
              style={imgStyle}
            />
          </a>
        </GelCol>
        <GelCol
          sm={12}
          md={6}
          style={{
            marginLeft: isXsScreen(screen) ? 0 : gelTokens.global.sizeBaseX16,
          }}
        >
          <GelRow
            gutter="medium"
            style={{
              marginBottom: gelTokens.global.sizeBaseX6,
            }}
          >
            <GelBoxLayout
              space="auto"
              gap="large"
              distribution="start"
              marginY={gelTokens.global.sizeBaseX6}
            >
              {brandData.footer.links.privacyPolicy ? (
                <GelLink
                  aria-label="Privacy Policy"
                  href={brandData.footer.links.privacyPolicy ?? "#"}
                  rel="noreferrer"
                  inverse={true}
                  backgroundColorOnHover={"none"}
                  style={navLinkStyle}
                >
                  Privacy Policy
                </GelLink>
              ) : null}
              {brandData.footer.links.security ? (
                <GelLink
                  aria-label="Security"
                  href={brandData.footer.links.security ?? "#"}
                  rel="noreferrer"
                  inverse={true}
                  backgroundColorOnHover={"none"}
                  style={navLinkStyle}
                >
                  Security
                </GelLink>
              ) : null}
            </GelBoxLayout>
          </GelRow>
          {brandData.footer.links.group ? (
            <GelParagraph style={textStyle}>
              {new Date().getFullYear()} © TAL Services Limited is a{" "}
              <GelLink
                target="_blank"
                aria-label="Dai-ichi Life Group"
                href={brandData.footer.links.group ?? "#"}
                rel="noreferrer"
                style={textStyle}
              >
                Dai-ichi Life Group
                <GelIcon
                  name="ExternalLink"
                  inline
                  width={gelTokens.global.sizeBaseUnit * 3}
                  color={gelTokens.brand.brandColorGrayT40}
                />
              </GelLink>{" "}
              Company.
            </GelParagraph>
          ) : null}
          {brandData.footer.text ? (
            <GelParagraph style={textStyle}>
              {brandData.footer.text}
            </GelParagraph>
          ) : null}
        </GelCol>
        <GelCol sm={12} md={2}></GelCol>
      </GelRow>
    </GelContainerLite>
  );
};

export default FooterAsteron;
