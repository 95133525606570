export const base64toBlobAsync = async (base64Data) => {
  return new Promise((resolve, reject) => {
    try {
      const sliceSize = 1024;
      const byteCharacters = window.atob(decodeURIComponent(base64Data));
      const bytesLength = byteCharacters.length;
      const slicesCount = Math.ceil(bytesLength / sliceSize);
      const byteArrays = new Array(slicesCount);

      if (bytesLength < 5 || byteCharacters.slice(0, 5) !== "%PDF-") {
        throw new Error("Invalid PDF data");
      }

      for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        const begin = sliceIndex * sliceSize;
        const end = Math.min(begin + sliceSize, bytesLength);

        const bytes = new Array(end - begin);
        for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
          bytes[i] = byteCharacters[offset].charCodeAt(0);
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes);
      }

      const blob = new Blob(byteArrays, { type: "application/pdf" });
      resolve(blob);
    } catch (error) {
      reject(error);
    }
  });
};
