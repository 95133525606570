import { configureTheming } from "@tal-gel/theming";
import ThemingPluginAsteron from "@tal-gel/theming-plugin-asteron";
import ThemingPluginGio from "@tal-gel/theming-plugin-gio";
import ThemingPluginApia from "@tal-gel/theming-plugin-apia";
import ThemingPluginSuncorp from "@tal-gel/theming-plugin-suncorp";
import ThemingPluginAami from "@tal-gel/theming-plugin-aami";

configureTheming({
  plugins: [
    ThemingPluginAsteron, // Add theme to tal-gel
    ThemingPluginGio,
    ThemingPluginApia,
    ThemingPluginSuncorp,
    ThemingPluginAami,
  ],
});
