import { useContext } from "react";
import { CorroUserContext } from "../App";

const GetSupportPhoneUsingKey = (name) => {

    const userContext = useContext(CorroUserContext);
    if (name.subContentKey && name.subContentKey !== "") {
        const retrieveKey = userContext.memberBrandState.memberBrand.subcontent;
        const filteredItems = retrieveKey.filter(item => item.name === name.subContentKey);
        const filteredsupportPhone = filteredItems[0]?.supportPhone || null;
        return filteredsupportPhone;

    } else {
        return null;
    }
};

export default GetSupportPhoneUsingKey;