import brandConfig from "../../config/brandConfig.json";
import { BrandConfigProps } from "../../props/brandConfigProps";
import FooterApia from "./FooterApia";
import FooterAsteron from "./FooterAsteron";
import FooterSuncorp from "./FooterSuncorp";
import FooterGIO from "./FooterGIO";
import FooterAami from "./FooterAami";

interface BrandFooters {
  [key: string]: React.FC<any>;
}

const brandFooters: BrandFooters = {
  asteron: (props: BrandConfigProps) => (
    <FooterAsteron {...brandConfig.asteronlife} />
  ),
  suncorp: (props: BrandConfigProps) => (
    <FooterSuncorp {...brandConfig.suncorplifeinsurance} />
  ),
  apia: (props: BrandConfigProps) => (
    <FooterApia {...brandConfig.apialifeinsurance} />
  ),
  gio: (props: BrandConfigProps) => (
    <FooterGIO {...brandConfig.giolifeinsurance} />
  ),
  aami: (props: BrandConfigProps) => (
    <FooterAami {...brandConfig.aamilifeinsurance} />
  ),
};

export default brandFooters;
