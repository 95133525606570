import {
  GelContainerLite,
  GelBoxLayout,
  GelScreenDetectorContext,
  GelRow,
  GelCol,
} from "@tal-gel/components";

import { getGelTokens } from "@tal-gel/theming";
import { useContext } from "react";
import brandConfig from "../config/brandConfig.json";

const gelTokens = getGelTokens();

const Header = ({ brand }) => {
  const brandData = brandConfig[brand] ?? brandConfig["asteronlife"];
  const sizeBase = gelTokens.global.sizeBaseUnit * brandData?.logo.sizeBase;
  const sizeBaseX25 = gelTokens.global.sizeBaseUnit * brandData.logo.sizeBaseXs;
  const logoMarginTop =
    gelTokens.global.sizeBaseUnit * brandData.logo.marginTop;
  const logoMarginBottom =
    gelTokens.global.sizeBaseUnit * brandData.logo.marginBottom;

  const { screen, isXsScreen } = useContext(GelScreenDetectorContext);
  const imgStyle = isXsScreen(screen)
    ? {
        height: sizeBaseX25,
        marginLeft: gelTokens.global.sizeBaseX6,
        marginRight: gelTokens.global.sizeBaseX6,
        marginTop: gelTokens.global.sizeBaseX6,
        marginBottom: gelTokens.global.sizeBaseX6,
      }
    : {
        height: sizeBase,
        marginTop: logoMarginTop,
        marginBottom: logoMarginBottom,
        marginLeft: gelTokens.global.sizeBaseX4,

        cursor: "pointer",
      };
  return (
    <GelContainerLite style={{ padding: 0 }}>
      <GelRow gutter="medium">
        <GelCol sm={12} md={3}></GelCol>
        <GelCol sm={12} md={6}>
          <GelBoxLayout distribution={brandData.logo.align} space={"auto"}>
            <a href={brandData.logo.link} target="_blank" rel="noreferrer">
              <img
                src={brandData.logo.image}
                alt={brandData.name}
                style={imgStyle}
              />
            </a>
          </GelBoxLayout>
        </GelCol>
      </GelRow>
      {brandData.logo.isUnderlined ? (
        <GelRow
          gutter="medium"
          style={{
            backgroundColor: getGelTokens().brand.brandColorPrimary1,
            height: getGelTokens().global.sizeBase,
            margin: 0,
          }}
        >
          <GelCol sm={12}></GelCol>
        </GelRow>
      ) : brandData.logo.isDivider ? (
        <hr></hr>
      ) : null}
    </GelContainerLite>
  );
};

export default Header;
