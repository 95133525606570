import {
  GelPageLayout,
  GelPageHeader,
  GelPageContent,
  GelPageFooter,
  GelRow,
  GelCol,
} from "@tal-gel/components";

import { GelThemeProvider } from "@tal-gel/theming";
import { GelScreenDetector } from "@tal-gel/components";
import { getGelTokens } from "@tal-gel/theming";

import { Outlet, useLocation } from "react-router-dom";
import { useState, createContext, memo, useEffect } from "react";
import { AxiosInterceptor } from "./api/axiosInterceptor";
import FooterContainer from "./Components/Footers/FooterContainer";
import Header from "./Components/Header";
import brandConfig from "./config/brandConfig.json";
import { BrandConfigProps } from "./props/brandConfigProps";

export const CorroUserContext = createContext({
  corroIdState: {
    corroId: "",
    setCorroId: (id: string) => { },
  },
  mobileNumberState: {
    mobileNumber: "",
    setMobileNumber: (id: string) => { },
  },
  linkState: {
    link: "",
    setLink: (id: string) => { },
  },
  channelState: {
    channel: "",
    setChannel: (id: string) => { },
  },
  textMobileNumberState: {
    textMobileNumber: "",
    setTextMobileNumber: (id: string) => { },
  },
  useDebugState: {
    useDebug: false,
    setUseDebug: (enabled: boolean) => { },
  },
  memberBrandState: {
    memberBrand: {} as BrandConfigProps,
    setMemberBrand: (key: BrandConfigProps) => { },
  },
  otpSentState: {
    otpSent: false,
    setOtpSent: (enabled: boolean) => { },
  },
  subContentKeyState: {
    subContentKey: "",
    setSubContentKey: (id: string) => { },
  },

});

function getBrandConfig(): any {
  const secondLevelDomain = getBrandKeyFromURL();

  const brandData =
    brandConfig[secondLevelDomain] ?? brandConfig["asteronlife"];
  return brandData;

}

function getBrandFromURL(): string {
  return getBrandConfig().gelLabel;
}

function getSubcontentKey(): string {
  var pathname = window.location.pathname;
  var hostnameParts = pathname.split('_');
  var lastPart = hostnameParts.length > 1 ? hostnameParts[hostnameParts.length - 1] : '';
  return lastPart;

}

function getCorroIdFromURL(): string {
  var pathname = window.location.pathname.slice(1);
  var hostnameParts = pathname.split('_');
  const corroId = hostnameParts[0];
  return corroId;
}

function getBrandKeyFromURL(): string {
  var hostname = window.location.hostname;
  const match = hostname.match(
    //eslint-disable-next-line
    /(?:.*\.)?([^\.]+)\.(?:com\.au|com)$/
  );
  return match?.[1] ?? "asteronlife";
}

const Inner = memo(function () {
  const brand = getBrandFromURL();
  const brandKey = getBrandKeyFromURL();
  return (
    <AxiosInterceptor>
      <GelThemeProvider theme={brand} mode={process.env.NODE_ENV}>
        <GelScreenDetector>
          <GelPageLayout>
            <GelPageHeader>
              <Header brand={brandKey} />
            </GelPageHeader>

            <GelPageContent
              style={{
                backgroundColor:
                  getGelTokens().global.themeColorBackgroundDefault,
                marginBottom: getGelTokens().global.sizeBaseX12,
                marginTop: getGelTokens().global.sizeBaseX12,
                minHeight: "100%",
              }}
            >
              <GelRow style={{ margin: 0 }}>
                <GelCol sm={12} md={4}></GelCol>
                <GelCol sm={12} md={4}>
                  <Outlet />
                </GelCol>
                <GelCol sm={12} md={4}></GelCol>
              </GelRow>
            </GelPageContent>

            <GelPageFooter>
              <FooterContainer brand={brand} />
            </GelPageFooter>
          </GelPageLayout>
        </GelScreenDetector>
      </GelThemeProvider>
    </AxiosInterceptor>
  );
});

export const App = () => {
  const [corroId, setCorroId] = useState(getCorroIdFromURL);
  const [mobileNumber, setMobileNumber] = useState("");
  const [link, setLink] = useState(useLocation().pathname);
  const [textMobileNumber, setTextMobileNumber] = useState("");
  const [channel, setChannel] = useState("");
  const [useDebug, setUseDebug] = useState(false);
  const [memberBrand, setMemberBrand] =
    useState<BrandConfigProps>(getBrandConfig);
  const [otpSent, setOtpSent] = useState(false);
  const [subContentKey, setSubContentKey] = useState(getSubcontentKey);


  useEffect(() => {
    if (process.env.REACT_APP_TRACKING_CODE) {
      // Include your tracking code here
      const script = document.createElement("script");
      script.src = process.env.REACT_APP_TRACKING_CODE;
      script.async = true;
      document.head.appendChild(script);

      const favicon = document.querySelector(
        'link[rel="icon"]'
      ) as HTMLLinkElement;
      if (favicon) {
        favicon.href = memberBrand.favicon ?? "/favicon.ico";
      }
    }
  });

  const contextState = {
    corroIdState: { corroId, setCorroId },
    mobileNumberState: { mobileNumber, setMobileNumber },
    linkState: { link, setLink },
    channelState: { channel, setChannel },
    textMobileNumberState: { textMobileNumber, setTextMobileNumber },
    useDebugState: { useDebug, setUseDebug },
    memberBrandState: { memberBrand, setMemberBrand },
    otpSentState: { otpSent, setOtpSent },
    subContentKeyState: { subContentKey, setSubContentKey },

  };

  return (
    <CorroUserContext.Provider value={contextState}>
      <Inner />
    </CorroUserContext.Provider>
  );
};

export default App;
