import {
  GelBoxLayout,
  GelCol,
  GelContainerLite,
  GelFootNote,
  GelLink,
  GelRow,
} from "@tal-gel/components";
import React from "react";

import { getGelTokens } from "@tal-gel/theming";
import { BrandConfigProps } from "../../props/brandConfigProps";

const FooterApia: React.FC<BrandConfigProps> = (brandData) => {
  return (
    <GelContainerLite
      style={{
        padding: 0,
        backgroundColor: getGelTokens().brand.brandColorWhite,
      }}
    >
      <GelRow
        gutter="medium"
        style={{
          backgroundColor: getGelTokens().brand.brandColorPrimary1,
          height: getGelTokens().global.sizeBaseHalf,
          margin: 0,
        }}
      >
        <GelCol sm={12}></GelCol>
      </GelRow>
      <GelRow
        gutter="medium"
        style={{
          padding: getGelTokens().global.sizeBaseX6,
          paddingBottom: 0,
        }}
      >
        <GelCol sm={12} md={3}></GelCol>
        <GelCol sm={12} md={6}>
          <GelBoxLayout
            style={{ padding: getGelTokens().global.sizeBase }}
            justify-content="space-between"
            distribution={"middle"}
          >
            <GelBoxLayout
              distribution="start"
              space={"auto"}
              style={{ padding: getGelTokens().global.sizeBase }}
            >
              {brandData?.footer?.socialNetworks?.map((item, index) => (
                <GelLink
                  key={index}
                  aria-label={item?.name ?? ""}
                  href={item.link ?? "#"}
                  rel="noreferrer"
                  style={{
                    border: "none",
                  }}
                >
                  <img src={item.icon ?? ""} alt={item?.name ?? ""} />
                </GelLink>
              ))}
            </GelBoxLayout>
            <GelBoxLayout style={{ textAlign: "end" }}>
              <GelLink
                style={{
                  border: "none",
                }}
                href={brandData.logo.link}
                rel="noreferrer"
                target="_blank"
              >
                <img src={brandData.logo.image} alt={brandData.name} />
              </GelLink>
            </GelBoxLayout>
          </GelBoxLayout>
        </GelCol>
        <GelCol sm={12} md={3}></GelCol>
      </GelRow>
      <GelRow
        gutter="medium"
        style={{
          padding: getGelTokens().global.sizeBaseX6,
          paddingTop: 0,
          margin: 0,
        }}
      >
        <GelCol sm={12} md={3}></GelCol>
        <GelCol sm={12} md={6}>
          <GelBoxLayout space="auto" gap="large" distribution="start">
            {brandData.footer.links.privacyPolicy ? (
              <GelLink
                style={{ border: "none" }}
                aria-label="Privacy Policy"
                href={brandData.footer.links.privacyPolicy ?? "#"}
                rel="noreferrer"
              >
                Privacy
              </GelLink>
            ) : null}
            {brandData.footer.links.security ? (
              <GelLink
                aria-label="Security"
                href={brandData.footer.links.security ?? "#"}
                rel="noreferrer"
              >
                Security
              </GelLink>
            ) : null}
          </GelBoxLayout>
          {brandData.footer.text ? (
            <GelFootNote marginTop={getGelTokens().global.sizeBaseX6}>
              {brandData.footer.text}
            </GelFootNote>
          ) : null}
        </GelCol>
        <GelCol sm={12} md={3}></GelCol>
      </GelRow>
    </GelContainerLite>
  );
};

export default FooterApia;
