import {
  GelContainerLite,
  GelHeading2,
  GelHeading6,
  GelParagraph,
} from "@tal-gel/components";
import { getGelTokens } from "@tal-gel/theming";
import { useContext } from "react";
import { CorroUserContext } from "../App";
import { Divider } from "../Components/Atoms/Divider";
import { PhoneLink } from "../Components/Atoms/PhoneLink";

const gelTokens = getGelTokens();

export const Success = () => {
  const userContext = useContext(CorroUserContext);
  document.title = "Document Download Success";

  return (
    <GelContainerLite>
      <GelHeading2>Document Download Success</GelHeading2>
      <GelHeading6>
        Success! Your document has been successfully downloaded and is ready for
        use.
      </GelHeading6>

      <Divider />
      <GelParagraph style={{ marginTop: gelTokens.global.sizeBaseX4 }}>
        If you have any questions or need assistance, please feel free to
        contact us on{" "}
        <PhoneLink
          phoneNumber={userContext.memberBrandState.memberBrand.supportPhone!}
          label={
            "Call our support team on " +
            userContext.memberBrandState.memberBrand.supportPhone!
          }
        />.
      </GelParagraph>
    </GelContainerLite>
  );
};
