import {
  GelBoxLayout,
  GelCol,
  GelContainerLite,
  GelLink,
  GelFootNote,
  GelRow,
  GelScreenDetectorContext,
} from "@tal-gel/components";
import React, { useContext } from "react";

import { getGelTokens } from "@tal-gel/theming";
import { BrandConfigProps } from "../../props/brandConfigProps";
import ContentOnDivider from "../Atoms/ContentOnDivider";

const gelTokens = getGelTokens();
const FooterAami: React.FC<BrandConfigProps> = (brandData) => {
  const { screen, isXsScreen } = useContext(GelScreenDetectorContext);
  const imgStyle = isXsScreen(screen)
    ? {
        height: gelTokens.global.sizeBaseX8,
      }
    : {
        height: gelTokens.global.sizeBaseX8,
        marginLeft: gelTokens.global.sizeBaseX8,
        cursor: "pointer",
      };

  return (
    <GelContainerLite
      style={{
        padding: gelTokens.global.sizeBaseX4,
        backgroundColor: gelTokens.brand.brandColorGrayT05,
        color: gelTokens.brand.brandColorBlack,
      }}
    >
      <GelRow
        gutter="medium"
        style={{ marginBottom: gelTokens.global.sizeBaseX2 }}
      >
        <GelCol sm={12} md={3}></GelCol>
        <GelCol sm={12} md={6}>
          <GelBoxLayout
            style={{ padding: gelTokens.global.sizeBase }}
            justify-content="space-between"
            distribution={"middle"}
          >
            <GelBoxLayout
              distribution="start"
              space={"auto"}
              style={{ padding: gelTokens.global.sizeBase }}
            >
              {brandData?.footer?.socialNetworks?.map((item, index) => (
                <GelLink
                  key={index}
                  aria-label={item?.name ?? ""}
                  href={item.link ?? "#"}
                  rel="noreferrer"
                  style={{
                    border: "none",
                  }}
                >
                  <img src={item.icon ?? ""} alt={item?.name ?? ""} />
                </GelLink>
              ))}
            </GelBoxLayout>
            <GelBoxLayout style={{ textAlign: "end" }}>
              <GelLink
                style={{
                  border: "none",
                }}
                href={brandData.logo.link}
                rel="noreferrer"
                target="_blank"
              >
                <img
                  src="/img/aami/AAMI.svg"
                  alt={brandData.name}
                  style={imgStyle}
                />
              </GelLink>
            </GelBoxLayout>
          </GelBoxLayout>
        </GelCol>
      </GelRow>
      <GelRow>
        <GelCol sm={12} md={3}></GelCol>
        <GelCol sm={12} md={6}>
          <ContentOnDivider link={null} text="LEGAL INFORMATION" />

          {brandData.footer.text ? (
            <GelFootNote
              marginTop={getGelTokens().global.sizeBaseX6}
              marginBottom={getGelTokens().global.sizeBaseX6}
            >
              {brandData.footer.text}
            </GelFootNote>
          ) : null}
          <ContentOnDivider
            link={brandData.footer.links.privacyPolicy}
            text="Privacy"
          />
        </GelCol>
      </GelRow>
    </GelContainerLite>
  );
};

export default FooterAami;
