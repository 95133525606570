import { GelContainerLite, GelSpinner, GelHeading2 } from "@tal-gel/components";

export const Loader = () => {
  return (
    <>
      <GelContainerLite style={{ textAlign: "center" }}>
        <GelSpinner
          large
          overlay
          overlayDescription={
            <div>
              <GelHeading2 inverse>Validating the link...</GelHeading2>
            </div>
          }
        />
      </GelContainerLite>
    </>
  );
};
